<template>
  <div class="container">
    <div class="row align-items-center justify-content-center">
      <div class="col-12 col-md-6">
        <div class="card">
          <div class="card-title">
            <h3 v-if="!error">Login Success</h3>
            <h3 v-else>Error!</h3>
          </div>
          <div class="card-text">
            <p v-if="!error">You are being redirected</p>
            <p v-else>{{error}}: {{errorDesc}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/client'

export const ROUTE_NAME = 'AuthCallback'

export default {
  name: ROUTE_NAME,
  data: () => ({}),
  computed: {
    code () {
      return this.$route.query.code
    },
    error () {
      return this.$route.query.error
    },
    errorDesc () {
      return this.$route.query.error_description
    },
    scope () {
      return this.$route.query.scope
    },
    state () {
      return this.$route.query.state
    }
  },

  mounted () {
    if (!this.error) {
      return api.exchangeCode(this.code, this.state).then(redirect => {
        console.log(redirect);
        if (redirect) {
          window.location = redirect
        }
      }).catch((err) => {
        console.log(err);
      });
    } else {
      setTimeout(() => window.location = '/', 2000)
    }
  }
}
</script>
